import { initializeApp } from "firebase/app";
import { getFirestore} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
const firebaseConfig = {
  apiKey: "AIzaSyBX-T6US5-bwZgPLIZbsYJYDnoXFSKujBk",
  authDomain: "aim-genius.firebaseapp.com",
  projectId: "aim-genius",
  storageBucket: "aim-genius.appspot.com",
  messagingSenderId: "381285941734",
  appId: "1:381285941734:web:88876ab729ae24caefd627"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)

