import React, { useState, useEffect } from 'react';
import {message } from 'antd';
import {  getAllStudents, searchStudent } from '../../../helpers/requests/student';
import moment from 'moment';

const Students = ({queryS, setD}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(queryS !== undefined ? queryS : "");
 

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    getAllStudents().then((response) => {
      setData(response);
      setLoading(false);
    }).catch((error) => {
      message.error("Failed to load students");
      setLoading(false);
    });
  };

  const handleSearch = () => {

    return data.filter(
      (student) =>
        student.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.studentID.toString().includes(searchQuery)
    );
  };


  return (
    <div className='p-4'>
      <div className=' flex  mb-4 '>
    
     <input

placeholder="Search by Name or ID"
value={searchQuery}
onChange={(e) => setSearchQuery(e.target.value)}
style={{ marginBottom: 20 }}
className=" cusIn bg-white flex-shrink w-[15vw] mr-auto mt-2 border rounded-2xl px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"

/>
    
  
<div className="flex  rounded-xl  w-1/3 bg-[#343a40] items-center justify-between ">
      <div>
        <h3 className="text-lg font-semibold ml-4 text-white">Student List</h3>
      </div>
      <div className="flex  ml-auto flex-col gap-2 shrink-0 sm:flex-row">
        
      
      </div>
    </div>
   
  </div>
  
  <div className="relative flex flex-col w-full h-full  text-slate-700 bg-white shadow-md rounded-xl bg-clip-border">

  <div className="p-0 rounded-xl overflow-scroll">
    <table className="w-full rounded-xl text-left table-auto min-w-max">
      <thead>
        <tr>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Member</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">ID</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Phone</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Batch</th>
        </tr>
      </thead>
      <tbody>
        {handleSearch().map(record => (
          <tr key={record._id}>
            <td className="p-4 border-b border-slate-200">
              <div className="flex items-center gap-3">
               <div className="flex flex-col">
                  <p onClick={(e) => {
                    setD(record.studentID)
                    searchStudent(record.firstName, "name").then((e)=>{
                      
                    })
                  }} className="text-sm font-semibold text-slate-700 cursor-pointer hover:text-blue-600 ">{`${record.firstName} ${record.lastName}`}</p>
                  <p className="text-sm text-slate-500">{record.email}</p>
                </div>
              </div>
            </td>
            <td className="p-4 border-b border-slate-200">
              <div className="flex flex-col">
                <p className="text-sm text-slate-500">{record.studentID}</p>
              </div>
            </td>
            <td className="p-4 border-b border-slate-200">
              <div className="flex flex-col">
                <a className="text-sm text-slate-500 hover:text-amber-500" href={`tel:+${record.phone}`}>+{record.phone}</a>
              </div>
            </td>
            <td className="p-4 border-b border-slate-200">
              <div className="w-max">
                <div className={`relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap ${record.status === 'online' ? 'bg-green-500/20 text-green-900' : 'bg-slate-100 text-slate-500'}`}>
                  <span>{record.batchId}</span>
                </div>
              </div>
            </td>
            
          
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="flex items-center justify-between p-3">

  </div>
</div>


     

    </div>
  );
};

export default Students;
