import { useEffect, useState } from "react"
import { checkAuth } from "../../helpers/firebase"
import { searchStudent } from "../../helpers/requests/student"
import { getStudentsBatch } from "../../helpers/requests/batch"
import Students from "./Components/students"
import ToDo from "./Components/todo"


const Dashboard = () => {

    const [currentStudent, setStudentData] = useState("")

    const [loaded, setLoaded] = useState(false)
 


    return(<>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
          rel="stylesheet"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          rel="stylesheet"
        />
        <div className="min-h-screen  bg-gradient-to-r from-pink-300 to-indigo-300">
          <div className="grid grid-cols-12 gap-0">
            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 xxl:col-span-8 px-6 py-6">
              <div className=" gap-6">
            <Students setD={setStudentData}/>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 xxl:col-span-4 px-6 py-6">
              {/* <div className="bg-white rounded-xl p-4 shadow-xl">
                <div className="flex flex-col justify-center items-center">
                  <div className="w-32 h-32 rounded-full bg-gray-300 border-2 border-white mt-2">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVxhAxJ4D7MOeTTj6kR9PBeZonW5HM7giKjTbEmR-HMBwf3G1VqGnlwpO1kWrdyIZu8_U&usqp=CAU"
                      className="rounded-full w-auto"
                    />
                  </div>
                  <p className="font-semibold text-xl mt-1">{studentData.firstName}</p>
                  <p className="font-semibold text-base text-gray-400">{studentData.studentID}</p>
                  <div
                    className="relative  p-4 rounded-lg shadow-xl w-full bg-cover bg-center h-32 mt-4"
                    style={{
                      backgroundImage:
                        'url("https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500")'
                    }}
                  >
                    <div className="absolute inset-0 bg-gray-800 bg-opacity-50" />
                    <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center justify-center">
                      <div>
                        <h3 className="text-center text-white text-lg">
                          Batch name
                        </h3>
                        <h3 className="text-center text-white text-3xl mt-2 font-bold">
                          {batchData.name}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

             <ToDo studentID={currentStudent}/>
             
            </div>
          </div>
        </div>
      </>
      )
}

export default Dashboard