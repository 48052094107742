import LoginPage from "../pages/Login"
import NotFound from "../pages/Not Found"
import Dashboard from "../pages/Dashboard"

const pages = (slug, query) => {
  
    const pageList = {
        "dashboard":  <Dashboard/>,
        "": <LoginPage/>,
    }   



    if (pageList[slug] !== undefined) {
        window.history.pushState(null, null, `./${slug}`);
        return pageList[slug];
      } else {
        window.history.pushState(null, null, `./Not-Found`);
        return <NotFound/>;
      }    
}

export default pages