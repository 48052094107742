import { deleteDoc } from "firebase/firestore";
import axiosBase from "./base"

export const addPayment = (id, amount, installmentNumber, paymentDate,expectedAmount) => {
    return new Promise((res, rej)=>{
        axiosBase("put", `student/payments/${id}` , {
            installmentNumber: installmentNumber,
            amount: amount,
            paymentDate: paymentDate,
            expectedAmount:expectedAmount
        } ).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}


export const getAllStudents = () => {
    return new Promise((res, rej)=>{
        axiosBase("get", "admin/students" ).then((e)=>{
            res(e)
        }).catch((e)=>{
        
            rej(e)
        })
    })
}

export const searchStudent = (searchQuery, searchType) => {
    return new Promise((res, rej)=>{
        axiosBase("post", "admin/student/search", 
            { query: searchQuery, type: searchType } 
        ).then((e)=>{
            res(e)
        }).catch((e)=>{
        console.log(e)
            rej(e)
        })
    })
}

export const updateStudent = (studentID, update ) => {
    return new Promise((res, rej)=>{
        axiosBase("put", `admin/student/${studentID}`, update).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}


export const getHomework = (batchID) =>{
    return new Promise((res, rej)=>{
        axiosBase("get", `teacher/homework/${batchID}`).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}

export const getattandenceData = () => {
    return new Promise((res, rej)=>{
        axiosBase("get", `teacher/attendance`).then((e)=>{
            res(JSON.parse(e))
        }).catch((e)=>{
            rej(e)
        })
    })

}