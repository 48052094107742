import React, { useState, useEffect } from "react";
import { db } from "./firebase.js";
import {
  collection,
  query,
  where,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

const ToDo = ({ studentID }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");

  const notesCollection = collection(db, "notes");

  // Fetch Notes for the Specific Student
  useEffect(() => {
    const fetchNotes = async () => {
      const studentQuery = query(notesCollection, where("studentID", "==", studentID));
      const data = await getDocs(studentQuery);
      setNotes(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchNotes();
  }, [studentID]); // Re-run whenever studentID changes

  // Add a New Note
  const addNote = async () => {
    if (newNote.trim()) {
      await addDoc(notesCollection, { text: newNote, done: false, studentID });
      setNewNote("");
      const studentQuery = query(notesCollection, where("studentID", "==", studentID));
      const data = await getDocs(studentQuery);
      setNotes(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
  };

  // Remove a Note
  const removeNote = async (id) => {
    await deleteDoc(doc(db, "notes", id));
    setNotes(notes.filter((note) => note.id !== id));
  };

  // Mark Note as Done
  const toggleDone = async (id, currentStatus) => {
    const noteDoc = doc(db, "notes", id);
    await updateDoc(noteDoc, { done: !currentStatus });
    setNotes(
      notes.map((note) =>
        note.id === id ? { ...note, done: !currentStatus } : note
      )
    );
  };

  return (
    <div className="bg-white rounded-xl py-4 my-4" style={{  fontFamily: "'Roboto', sans-serif" }}>
    <h1
      style={{
        textAlign: "center",
        color: "#343A40",
        fontSize: "2rem",
        marginBottom: "20px",
      }}
    >
      To-Do List for Student: <span style={{ color: "#007BFF" }}>{studentID}</span>
    </h1>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        marginBottom: "20px",
      }}
    >
      <input
        type="text"
        placeholder="Add a new note..."
        value={newNote}
        onChange={(e) => setNewNote(e.target.value)}
        style={{
          padding: "10px 15px",
          width: "70%",
          border: "1px solid #ced4da",
          borderRadius: "20px",
          outline: "none",
          fontSize: "16px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      />
      <button
        onClick={addNote}
        style={{
          padding: "10px 20px",
          backgroundColor: "#007BFF",
          color: "white",
          border: "none",
          borderRadius: "20px",
          fontSize: "16px",
          cursor: "pointer",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#007BFF")}
      >
        Add Note
      </button>
    </div>
    <div>
      {notes.map((note) => (
        <div
          key={note.id}
          className="w-[90%] mx-auto"
          style={{
            border: "1px solid #e9ecef",
            padding: "10px",
            marginBottom: "15px",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: note.done ? "#EAF8E6" : "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
            transition: "transform 0.2s",
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <span
            style={{
              textDecoration: note.done ? "line-through" : "none",
              color: note.done ? "#6c757d" : "#212529",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            {note.text}
          </span>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={() => toggleDone(note.id, note.done)}
              style={{
                padding: "10px",
                backgroundColor: note.done ? "#FFC107" : "#28A745",
                color: "white",
                border: "none",
                borderRadius: "10px",
                fontSize: "14px",
                cursor: "pointer",
                transition: "background-color 0.3s",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = note.done
                  ? "#e0a800"
                  : "#1e7e34")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = note.done
                  ? "#FFC107"
                  : "#28A745")
              }
            >
              {note.done ? "Undo" : "Done"}
            </button>
            <button
              onClick={() => removeNote(note.id)}
              style={{
                padding: "10px",
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
                borderRadius: "10px",
                fontSize: "14px",
                cursor: "pointer",
                transition: "background-color 0.3s",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#bd2130")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#DC3545")}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default ToDo;
