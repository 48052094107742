import { signOut } from 'firebase/auth'
import pages from '../../helpers/pages'
import logo from './logo.png'
import { auth } from '../../helpers/firebase'
const Header = ({loggedIn, changePage, query}) => {


    return(
        <nav className="flex items-center justify-between flex-wrap bg-[#343a40] py-4 lg:px-12 shadow border-solid border-t-2 border-blue-700">
        <div className="flex justify-between lg:w-auto w-full lg:border-b-0 pl-6 pr-2 border-solid border-b-2 border-gray-300 pb-5 lg:pb-0">
          <div className="flex items-center flex-shrink-0 text-gray-800 mr-16">
           <img src={logo} onClick={(e)=>{changePage(pages("", query))}}  className='cursor-pointer w-24 hover:scale-105 transaction duration-[200ms]'/>
          </div>
          <div className="block lg:hidden ">
            <button
              id="nav"
              className="flex items-center px-3 py-2 border-2 rounded text-white border-blue-700 hover:text-white hover:border-blue-700"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="menu w-full lg:block flex-grow lg:flex lg:items-center lg:w-auto lg:px-3 px-8">
          
        
          {

            loggedIn === true ? <div className="flex ">
            <p
               onClick={(e)=>{changePage(pages("dashboard", query))}}  
              className="block text-md cursor-pointer px-4 py-2 rounded text-white ml-2 font-bold hover:text-white mt-4 hover:bg-blue-700 lg:mt-0"
            >
              Dashboard
            </p>
            <p
               onClick={(e)=>{
              
                signOut(auth).then((e)=>{
                  changePage(pages("", query))
                                })
              
               }}  
              className="block text-md cursor-pointer px-4 py-2 rounded text-white ml-2 font-bold hover:text-white mt-4 hover:bg-blue-700 lg:mt-0"
            >
              Log out
            </p>
           
          </div>
          : <div className="flex ">
         
          <p
               onClick={(e)=>{changePage(pages("login", query))}}  
          
            className=" block cursor-pointer  text-md px-4  ml-2 py-2 rounded text-white font-bold hover:text-white mt-4 hover:bg-blue-700 lg:mt-0"
          >
            login
          </p>
        </div>

          }
        </div>
      </nav>
      
      )

}

export default Header